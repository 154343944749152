<template>
    <DashboardWrapper>
        <div class="container mx-auto"> 
            <div class="mx-4 sm:mx-8">
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    Dashboard
                </h2>

                <div class="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3 mt-10">
                    
                    <router-link class="group" :to="{ name: 'members' }">
                        <div class="px-6 py-4 bg-scorelitgray sm:px-6 shadow sm:rounded-md bg-gradient-to-r group-hover:from-gradient-orange group-hover:to-gradient-red transition-all duration-300">
                            <div class="flex items-center text-white">
                                <svg class="flex-shrink h-6 w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                                </svg>
                                <h3 class="block font-bold">
                                    Aantal leden
                                </h3>
                                <svg class="ml-auto h-6 w-6 transition duration-200 transform group-hover:scale-105 group-hover:translate-x-1 group-hover:-translate-y-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </div>
                            <p class="text-white text-5xl font-bold">
                                174
                            </p>
                        </div>
                    </router-link>

                    <router-link class="group" :to="{ name: 'coaches' }">
                        <div class="px-6 py-4 bg-scorelitgray sm:px-6 shadow sm:rounded-md bg-gradient-to-r group-hover:from-gradient-orange group-hover:to-gradient-red transition duration-200">
                            <div class="flex items-center text-white">
                                <svg class="flex-shrink h-6 w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                                </svg>
                                <h3 class="block font-bold">
                                    Aantal coaches
                                </h3>
                                <svg class="ml-auto h-6 w-6 transition duration-200 transform group-hover:scale-105 group-hover:translate-x-1 group-hover:-translate-y-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </div>
                            <p class="text-white text-5xl font-bold">
                                16
                            </p>
                        </div>
                    </router-link>
                    
                    <router-link class="group" :to="{ name: 'teams' }">
                        <div class="px-6 py-4 bg-scorelitgray sm:px-6 shadow sm:rounded-md bg-gradient-to-r group-hover:from-gradient-orange group-hover:to-gradient-red transition duration-200">
                           <div class="flex items-center text-white">
                                <svg class="flex-shrink h-6 w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                                </svg>
                                <h3 class="block font-bold">
                                    Aantal teams
                                </h3>
                                <svg class="ml-auto h-6 w-6 transition duration-200 transform group-hover:scale-105 group-hover:translate-x-1 group-hover:-translate-y-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </div>
                            <p class="text-white text-5xl font-bold">
                                11
                            </p>
                        </div>
                    </router-link>

                    <router-link class="group" :to="{ name: 'matches.index' }">
                        <div class="px-6 py-4 bg-scorelitgray sm:px-6 shadow sm:rounded-md bg-gradient-to-r group-hover:from-gradient-orange group-hover:to-gradient-red transition duration-200">
                           <div class="flex items-center text-white">
                                <svg class="flex-shrink h-6 w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                                </svg>
                                <h3 class="block font-bold">
                                    Wedstrijden geüpload
                                </h3>
                                <svg class="ml-auto h-6 w-6 transition duration-200 transform group-hover:scale-105 group-hover:translate-x-1 group-hover:-translate-y-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </div>
                            <p class="text-white text-5xl font-bold">
                                56
                            </p> 
                        </div>
                    </router-link>

                    <router-link class="group" :to="{ name: 'transactions' }">
                        <div class="px-6 py-4 bg-scorelitgray sm:px-6 shadow sm:rounded-md bg-gradient-to-r group-hover:from-gradient-orange group-hover:to-gradient-red transition duration-200">
                           <div class="flex items-center text-white">
                                <svg class="flex-shrink h-6 w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                                    <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                                </svg>
                                <h3 class="block font-bold">
                                    Aantal analyses
                                </h3>
                                <svg class="ml-auto h-6 w-6 transition duration-200 transform group-hover:scale-105 group-hover:translate-x-1 group-hover:-translate-y-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </div>
                            <p class="text-white text-5xl font-bold">
                                34
                            </p>
                        </div>
                    </router-link>

                    <router-link class="group" :to="{ name: 'overview'}">
                        <div class="px-6 py-4 bg-scorelitgray sm:px-6 shadow sm:rounded-md bg-gradient-to-r group-hover:from-gradient-orange group-hover:to-gradient-red transition duration-200">
                           <div class="flex items-center text-white">
                                <svg class="flex-shrink h-6 w-6 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                                </svg>
                                <h3 class="block font-bold">
                                    Totale inkomsten
                                </h3>
                                <svg class="ml-auto h-6 w-6 transition duration-200 transform group-hover:scale-105 group-hover:translate-x-1 group-hover:-translate-y-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                                </svg>
                            </div>
                            <p class="text-white text-5xl font-bold">
                                110,50€
                            </p>
                        </div>
                    </router-link>
                </div>
                
            </div>
        </div>
    </DashboardWrapper>
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";

export default {
    name: "dashboard",
    components: {
        DashboardWrapper,
    }
}
</script>